:root {
	--color-lightest: #FFF;
	--color-light: #e5e5e5;
	--color-mid: #808080;
	--color-darkest: #1a1a1a;
	--color-transparent: transparent;

	--color-green: #24b283;
  --color-mid-green: #336655;
	--color-light-green: #2EE5A8;


		--color-grey: #F2F2F2;


	--color-body-background: var(--color-lightest);
	--color-border: var(--color-mid);

	--site-max-width: 90rem;

	--horizontal-spacing: 1.5rem;
	--horizontal-spacing-desktop: 4.5rem;
	--horizontal-spacing-large: 2.5rem;

	--module-padding: 6.25rem;

	--font-brand-body: "Poppins", sans-serif;
	--font-brand-body-weight: 500;
	--font-brand-bold: "Poppins", sans-serif;
	--font-brand-bold-weight: 700;

	--font-size-level1-mobile: 2.625rem;
	--font-size-level2-mobile: 2.375rem;
	--font-size-level3-mobile: 2rem;
	--font-size-level4-mobile: 1.5rem;
	--font-size-level5-mobile: 1.25rem;
	--font-size-level6-mobile: 1.25rem;

	--font-size-level1: 3.5rem;
	--font-size-level2: 5rem;
	--font-size-level3: 4rem;
	--font-size-level4: 2.6rem;
	--font-size-level5: 2rem;
	--font-size-level6: 1.5rem;
	--font-size-level7: 1.2rem;

	--screen-size-large: 75rem;
}
