.paddingTop {
	padding-top: var(--module-padding);
}

.backgroundGreen {
	background: var(--color-green);
}

.backgroundBlack {
	background: var(--color-darkest);
	color: var(--color-lightest);
}

.mediaColumns {
	padding-bottom: var(--module-padding);
}

.columns {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: -7.5rem;
}

.column {
	margin-bottom: 7.5rem;
	text-align: center;
	width: 100%;

	@media (min-width: 75rem) {
		padding: 0 var(--horizontal-spacing);
		width: calc(50% - var(--horizontal-spacing));
	}
}

.threeColumns .column {
	@media(min-width: 75rem) {
		width: calc(33.33% - var(--horizontal-spacing));
	}
}

.text {
	margin: 0 auto;
	max-width: 31.25rem;
}

.column .title {
	margin: 2.5rem 0 2rem;
}

.column .title span {
	margin-bottom: 0;
}

.column .text p:last-child {
	margin-bottom: 0;
}

.column img,
.column video {
	display: block;
	margin: 0 auto;
	max-width: 25.75rem;
	width: 80%;
}

.footer {
	display: flex;
	justify-content: center;
}

.footer a {
	margin-top: 3.5rem;
}