@import "./fonts.css";
@import "./variables.css";

*,
::after,
::before {
	box-sizing: border-box;
}

body {
	background: var(--color-body-background);
	color: var(--color-body-color);
	font-family: var(--font-brand-body);
	font-weight: var(--font-brand-body-weight);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin: 0;
	overflow-x: hidden;
	padding: 0;
}

main {
	padding-top: 5.75rem;

	@media (min-width: 62.5rem) {
		padding-top: 4.8125rem;
	}
}

h1 {
	font-size: var(--font-size-level1-mobile);
	margin-bottom: 3rem;
	font-weight: var(--font-brand-bold-weight);

	@media (min-width: 62.5rem) {
		font-size: var(--font-size-level1);
		margin-bottom: 2rem;
	}
}

h2 {
	font-size: var(--font-size-level2-mobile);
	margin-bottom: 1.5rem;
	font-weight: var(--font-brand-bold-weight);

	@media (min-width: 62.5rem) {
		font-size: var(--font-size-level2);
	}
}

h3 {
	font-size: var(--font-size-level3-mobile);
	margin-bottom: 2rem;

	@media (min-width: 62.5rem) {
		font-size: var(--font-size-level3);
	}
}

h4 {
	font-size: var(--font-size-level4-mobile);
	line-height: 1.5;
	margin-bottom: 1.5rem;

	@media (min-width: 62.5rem) {
		font-size: var(--font-size-level4);
	}
}

h5 {
	font-size: var(--font-size-level5-mobile);
	line-height: 1.7;
	margin-bottom: 1rem;

	@media (min-width: 62.5rem) {
		font-size: var(--font-size-level5);
		line-height: 1.67;
	}
}

h6 {
	font-family: var(--font-brand-bold);
	font-weight: var(--font-brand-bold-weight);
	font-size: var(--font-size-level6-mobile);
	margin-bottom: 1rem;

	@media (min-width: 62.5rem) {
		font-size: var(--font-size-level6);
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {

	margin-top: 0;
}

h1 strong,
h2 strong,
h3 strong,
h4 strong,
h5 strong {
	font-weight: var(--font-brand-bold-weight);
}

strong {
	font-family: var(--font-brand-bold);
	font-weight: var(--font-brand-bold-weight);
}

input {
	font-family: var(--font-brand-body);
}

p {
	line-height: 1.7;
}

// slider home hero styles
.carousel_box_wrapper {
	.slick-list {
		height: 100%;
	}
	.slick-slide {
		height: 100%;
	}
	.slick-track {
		height: 100%;
	}
}
