.alignLeft {

}

.alignCentered {
  text-align: center;

  h1,
  p,
  svg {
    margin-left: auto;
    margin-right: auto;
  }
}

.alignRight {
  text-align: right;
}

.hero {
  display: flex;
  min-height: calc(80vh - 75px);
  position: relative;
  width: 100%;
  background: var(--color-darkest);
}

.borderBottom {
  border-bottom: 1px solid var(--color-border);
}

.textColorDark {
  color: var(--color-darkest);

  .arrowDown path {
    stroke: var(--color-darkest);
  }
}

.textColorLight {
  color: var(--color-lightest);

  .arrowDown path {
    stroke: var(--color-lightest);
  }
}

.background,
.fallbackImage {
  height: 100%;
  position: absolute;
  user-select: none;
  width: 100%;
}

.background video,
.background img,
.fallbackImage {
  position: absolute;
  height: 100%;
  left: 0;
  object-fit: cover;
  top: 0;
  width: 100%;
}

.background video {
  z-index: 1;
}

.fallbackImage {
  z-index: 0;
}

.hideSlide {
  opacity: 0;
  transition: opacity 500ms ease;
}

.showSlide {
  composes: hideSlide;
  align-items: flex-end;
  display: flex;
  opacity: 1;
}

.overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 5rem 0 0;
  position: relative;
  z-index: 1;
  max-width: 650px;
}

.textBackgroundBlack,
.textBackgroundGreen {
	margin: auto 0;
	height: fit-content;
	padding: calc(var(--module-padding) / 2);
}


.textBackgroundBlack {
	background-color: var(--color-darkest);
}

.textBackgroundGreen {
	background-color: var(--color-light-green);
}

.textBackgroundBlack .heroBottom,
.textBackgroundGreen .heroBottom {
	margin-bottom: 0;
}

.contentRight {
	margin: auto 0;
	padding: calc(var(--module-padding) / 2);

	@media (min-width: 62.5rem) {
		margin-left: var(--module-padding);
	}
}

.downloadForm {
	background: rgba(255, 255, 255, 0.45);

	@media (min-width: 62.5rem) {
		min-width: 30rem;
	}
}

.downloadForm h4 {
	line-height: 1.1;
}

.solidBackground {
	background: var(--color-darkest);
}


.heroCarousel {
}

.hero h1,
.hero h2,
.hero h3 {
  line-height: 1;
  margin-top: 0;
  margin-bottom: 2rem;
  max-width: 70rem;
  padding: 0;

  @media (min-width: 62.5rem) {
    margin-bottom: 3rem;
  }
}

.hero h3 {
  line-height: 1.25;
  margin-bottom: 1.5rem;
}

.hero strong {
  width: 100%;
}

.text {
  margin-bottom: 2.5rem;

}

.textHomepage {
  composes: text;
}

.textHomepage p {
  font-size: 1.5rem;
  line-height: 1.42;

  @media (min-width: 62.5rem) {
    line-height: 1.67;
  }
}

.text h4 {
  max-width: 35rem;
  margin: 0;
  margin-top: 2rem;
  font-size: var(--font-size-level6);
  line-height: 1.6
}

.darkText {
	color: var(--color-darkest);
}

.text p {
  max-width: 35rem;
  margin: 0;
  margin-top: 0.5rem;
  font-size: 1rem;
  line-height: 1.6
}

.hero :global(.basePadding) {
   display: flex;
   flex-direction: column;
  height: 100%;

  @media (min-width: 62.5rem) {
	flex-direction: row;
  }
}

.heroBottom {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;
}


.hero h1 {
  margin-bottom: 0.5rem;
  z-index: 2;
}

.hero h1 strong {
  position: relative;
  z-index: 2;
  text-decoration: underline;
  text-decoration-color: var(--color-light-green);
  display: block
}

.heroUnderline {
  position: relative;
  z-index: 2;
}

.heroUnderline:before {
  content: '';
  background: var(--color-light-green);
  width: 100%;
  height: 5px;
  bottom: 10px;
  left: 0;
  display: inline-block;
  position: absolute;
  z-index: -1
}


.cta {
}

.cta_link {
}


// carousel_box
.carousel_box {
  position: absolute !important;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .slick-list {
    height: 100%;
  }

  &_item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.hero_button {
  background-color: #2EE5A8;
  height: 45px;
  display: inline-flex;
  text-decoration: none;
  color: #181919;
  align-items: center;
  transition: all .2s;
  flex-shrink: 0;
  align-self: flex-start;
  padding: 0 20px;

  svg {
    margin-left: 10px;
  }

  &:hover {
    background-color: #1aad7b;
  }
}

.inputWrapper input {
	background-color: var(--color-darkest);
	border-radius: 0.25rem;
	color: var(--color-lightest);
}

.inputWrapper label {
	border-radius: 0.25rem;
	color: var(--color-lightest);
	line-height: 1.4375em !important;
	background-color: var(--color-darkest);
	padding: 0 0.25rem;
}

.inputWrapper label:global(.Mui-focused) {
	color: var(--color-light-green);
}

.inputWrapper fieldset {
	border-color: var(--color-light-green) !important;
}

.inputWrapper fieldset legend span {
	color: var(--color-light-green);
}

.inputWrapper :global(.MuiOutlinedInput-root) {
	background-color: var(--color-darkest);
}