.paddingTop {
	padding-top: var(--module-padding);
}

.blogPost {
	padding-bottom: 3rem;
}

.backgroundGreen {
	background: var(--color-green);
}

.backgroundBlack {
	background: var(--color-darkest);
	color: var(--color-lightest);
}

.divider {
	padding-bottom: var(--module-padding);
}

.divider hr {
	border: 0;
	border-bottom: 1px solid var(--color-darkest);
	margin: 0;
}

.backgroundBlack hr {
	border-color: var(--color-lightest);
}