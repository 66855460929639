.paddingTop {
	padding-top: var(--module-padding);
}

.backgroundGreen {
	background: var(--color-green);
}

.backgroundBlack {
	background: var(--color-darkest);
	color: var(--color-lightest);
}

.textTile {
	padding-bottom: var(--module-padding);
}

.content {
	display: flex;
}

.eyebrow span {
	margin-bottom: 2.5rem;
}

.hasText {
	align-items: center;
	flex-direction: column;
	text-align: center;
}

.tilesOnly {
	flex-direction: column;

	.copy {
		margin: 0 auto;
		text-align: center;
	}
}

.text p {
	font-size: 2rem;
	margin-top: 0;
}

.text p:last-child {
	margin-bottom: 0;
}

.text strong {
	color: var(--color-green);
	font-family: var(--font-brand-body);
	font-weight: var(--font-brand-body-weight);
}

.copy {
	width: 50%;
}

.tiles {
	display: flex;
	flex-wrap: wrap;
	margin: auto 0;
}

.textItem {
	display: flex;
	flex-direction: column;
	min-height: 15rem;
	justify-content: flex-start;
	padding: 0 1rem;
	width: 100%;

	@media (min-width: 62.5rem) {
		width: 33.33%;
	}
}

.textItem h5 {
	margin-bottom: 0;
}

.textItem p {
	font-size: 0.875rem;

	@media (min-width: 62.5rem) {
		font-size: 1rem;
	}
}

.transitionIn {
	animation: transitionIn 150ms;
}

@keyframes transitionIn {
	0% {
		transform: translate(0, -3rem);
		opacity: 0;
	}
	100% {
		transform: translate(0, 0);
		opacity: 1;
	}
}