.header {
	background: var(--color-darkest);
	border-bottom: 1px solid var(--color-border);
	padding: 1.5rem 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 10;

	@media (min-width: 62.5rem) {
		padding: 1.875rem 0;
	}
}

.navigation {
	align-items: center;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-left: auto;

	@media (min-width: 62.5rem) {
		position: relative;
	}
}

.hamburger {
	background: transparent;
	border: 0;
	height: 12px;
	outline: 0;
	overflow: hidden;
	padding: 0;
	position: absolute;
	right: 1rem;
	text-indent: -99rem;
	top: 1rem;
	width: 1.25rem;

	&:before {
		content: "";
		background: var(--color-lightest);
		display: block;
		height: 2px;
		position: absolute;
		top: 0;
		transform-origin: center center;
		transition: all 200ms ease-in-out;
		width: 100%;
	}

	&:after {
		content: "";
		bottom: 1px;
		background: var(--color-lightest);
		height: 2px;
		display: block;
		position: absolute;
		transform-origin: center center;
		transition: all 200ms ease-in-out;
		width: 100%;
	}


	@media (min-width: 62.5rem) {
		display: none;
	}
}

.hamburgerOpen {
	composes: hamburger;

	&:before {
		top: 5px;
      	transform: rotate(-45deg);
	}

	&:after {
		bottom: 5px;
		transform: rotate(45deg);
	}

}

.logo {
	display: flex;
}

.logo img {
	height: 46px;
	width: 140px;
}

.linkItem {
	align-items: center;
	display: flex;
	gap: 0.25rem;
}

.menu {
	align-items: flex-start;
	background: var(--color-darkest);
	border-top: 1px solid var(--color-border);
	left: 0;
	flex-direction: column;
	height: 0;
	opacity: 0;
	position: fixed;
	transition: all 200ms ease;
	top: 4rem;
	visibility: hidden;
	width: 100%;

	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;

	@media (min-width: 62.5rem) {
		align-items: center;
		border: 0;
		flex-direction: row;
		height: auto;
		opacity: 1;
		gap: var(--horizontal-spacing-large);
		position: relative;
		top: 0;
		visibility: visible;
		width: auto;
	}
}

.menuOpen {
	@media (max-width: 62.49rem) {
		composes: menu;
		height: calc(100vh - 4rem);
		opacity: 1;
		visibility: visible;
	}
}

.menu li:first-child {
	padding-top: 6rem;

	@media (min-width: 62.5rem) {
		padding-top: 0;
	}
}

.menu li {
	margin-bottom: 2.875rem;

	@media (min-width: 62.5rem) {
		margin-bottom: 0;
	}
}

.navigation li a,
.navigation li button,
.navigation li span {
	color: var(--color-lightest);
	font-size: 1.5rem;
	text-decoration: none;
	font-family: var(--font-brand-bold);

	@media (min-width: 62.5rem) {
		font-size: 0.875rem;
	}
}

.navigation li a,
.navigation li button {
	background: transparent;
	border: 0;
	display: flex;
}

.navigation li a {
	padding: 0.5rem 0 0.625rem;
	position: relative;
}

.navigation li a :global(.squiggle),
.navigation li button :global(.squiggle) {
	bottom: 0;
	height: 0.5rem;
	left: 0;
	transform: rotate3d(1, 0, 0, -90deg);
	transition: all 150ms ease;
	position: absolute;
	width: 100%;
}

.navigation li a:hover :global(.squiggle) {
	transform: rotate3d(1, 0, 0, 0deg);
}

.navigation li a path,
.navigation li button path {
	fill: none;
	stroke: var(--color-green);
	stroke-width: 2px;
	stroke-linecap: round;
}

.navigation li .activeLink  {
	color: var(--color-green);
	position: relative;
}

.navigation li .activeLink:after {
	content: "";
	background: var(--color-green);
	bottom: 3px;
	display: block;
	height: 1px;
	left: 0;
	opacity: 1;
	position: absolute;
	transition: opacity 100ms ease;
	width: 100%;
}

.navigation li .activeLink:hover:after {
	opacity: 0;
}