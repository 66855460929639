.paddingTop {
	padding-top: var(--module-padding);
}

.backgroundGreen {
	background: var(--color-green);
}

.backgroundBlack {
	background: var(--color-darkest);
	color: var(--color-lightest);
}

.ctaBlock {
	padding-bottom: var(--module-padding);
}

.ctaBlock h3 {
	font-size:var(--font-size-level5)
}
.ctaBlock h4 {
	margin-bottom: 2rem;

	@media(min-width: 62.5rem) {
		margin-bottom: 2.5rem;
	}
}

.imageAlignRight .fixedContainer {
	flex-direction: column-reverse;

	@media (min-width: 62.5rem) {
		flex-direction: row-reverse;
	}
}

.imageAlignLeft .fixedContainer {
	flex-direction: column-reverse;

	@media (min-width: 62.5rem) {
		flex-direction: row;
	}
}

.fixedContainer {
	display: flex;
	position: relative;
}

.imageContainer {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.textContainer {
	text-align: center;
}

.eyebrow span {
	margin-bottom: 2.5rem;
}

.cta {
	margin-top: 4.375rem;
}

.imageContainer {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-bottom: 2.5rem;
}

.imageContainer a {
	display: flex;
}

.imageContainer img {
	width: auto;
}