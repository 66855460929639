.paddingTop {
	padding-top: var(--module-padding);
}

.backgroundGreen {
	background: var(--color-green);
}

.backgroundBlack {
	background: var(--color-darkest);
	color: var(--color-lightest);
}

.logoSlider span {color: var(--color-mid-green);}
.logoSlider span:before {background: var(--color-mid-green);}

.logoSlider {
	padding-bottom: var(--module-padding);
}

.content {
	display: flex;
}

.hasText {
	justify-content: space-between;

	.logos {
		width: 50%;

		@media (min-width: 75rem) {
			padding-left: 8%;
		}
	}

	.copy {
		@media (min-width: 75rem) {
			padding-right: 8%;
		}
	}
}

.logosOnly {
	flex-direction: column;

	.copy {
		margin: 0 auto;
		text-align: center;
	}
}

.text h2 {font-size: var(--font-size-level5);}
.text h3 {font-size: var(--font-size-level6);}

.text p {
	font-size: 2rem;
	margin-top: 0;
}

.text p:last-child {
	margin-bottom: 0;
}

.text strong {
	color: var(--color-green);
	font-weight: var(--font-brand-body-weight);
}

.copy {
	width: 60%;
}

.logos {
	display: flex;
	flex-wrap: wrap;
	margin: auto 0;
}

.logo {
	align-items: center;
	display: flex;
	height: 10rem;
	justify-content: center;
	width: 50%;

	@media (min-width: 56rem) {
		width: 20%;
	}
}

.logo img {
	height: 120%;
}

.transitionIn {
	animation: transitionIn 150ms;
}

@keyframes transitionIn {
	0% {
		transform: translate(0, -3rem);
		opacity: 0;
	}
	100% {
		transform: translate(0, 0);
		opacity: 1;
	}
}
