.loading {
	align-items: center;
	display: flex;
	height: 70vh;
	justify-content: center;
	width: 100%;
}

.loading svg {
	animation: grow 700ms ease;
	height: 8px;
}

.loading path {
	fill: none;
	stroke: var(--color-green);
	stroke-width: 2px;
	stroke-linecap: round;
}

@keyframes grow {
	0% {
		width: 0rem;
	}
	100% {
		width: 18.75rem;
	}
}