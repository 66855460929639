.paddingTop {
	padding-top: var(--module-padding);
}

.backgroundGreen {
	background: var(--color-green);
}

.backgroundBlack {
	background: var(--color-darkest);
	color: var(--color-lightest);
}

.textCarousel {
	padding-bottom: var(--module-padding);
	position: relative;
}

.eyebrow {
	text-align: center;
}

.carouselContainer {
	position: relative;
}

.slide {
	min-height: 30rem;
	text-align: center;

	@media (min-width: 62.5rem) {
		min-height: 40rem;
	}
}

.backgroundImage {
	display: none;
	
	@media (min-width: 62.5rem) {
		display: block;
		height: 100%;
		object-fit: cover;
		position: absolute;
		width: 100%;
	}
}

.eyebrow span {
	margin-bottom: 2.5rem;
}

.slide p {
	font-size: 0.9375rem;
	margin-left: auto;
	margin-right: auto;
	margin-top: 0;
	width: 90%;

	@media (min-width: 62.5rem) {
		font-size: 1rem;
		line-height: 2.5;
		width: 60%;
	}
}

.dots {
	display: flex;
	justify-content: center;
	margin-top: 2rem;
	position: relative;
	z-index: 1;
}
  
.dot {
	background: transparent;
	border: 1px solid var(--color-darkest);
	border-radius: 50%;
	cursor: pointer;
	height: 0.75rem;
	margin: 0 0.625rem;
	padding: 0;
	width: 0.75rem;
}
  
.dot:focus {
	outline: none;
}
  
.dotActive {
	composes: dot;
	border: 1.5px solid var(--color-lightest);
	box-shadow: 0 0 0 1.2px var(--color-green);
	background: var(--color-green);
}
  
.withImage {
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
	width: 100%;
}