.eyebrow {
	display: inline-block;
	font-family: var(--font-brand-bold);
	font-weight: var(--font-brand-bold-weight);
	font-size: 0.875rem;
	letter-spacing: 0.07rem;
	margin-bottom: 1rem;
	text-transform: uppercase;
	position:relative;
 padding-left:20px;
}

.eyebrow:before {content:''; width: 30px; height:2px; position:relative; left:-10px; bottom: 3px; display: inline-block; background: var(--color-light-green);}
