.paddingTop {
	padding-top: var(--module-padding);
}

.backgroundGreen {
	background: var(--color-green);
}

.backgroundBlack {
	background: var(--color-darkest);
	color: var(--color-lightest);
}

.media {
	padding-bottom: var(--module-padding);
}

.removePadding {
	padding-bottom: 0;
	padding-top: 0;
}

.blogPost {
	padding-bottom: 3rem;
}

.media img,
.media video {
	display: block;
	max-width: 100%;
	width: 100%;
}

.alignCentered {
	text-align: center;
}

.alignLeft {
	text-align: left;
}

.alignRight {
	text-align: right;
}

.text {
	font-size: 0.875rem;
	margin: 2.0625rem 0 0;
}

.text:empty {
	display: none;
}

.text p:last-child {
	margin-bottom: 0;
}

.cta {
	margin-top: 4.375rem;
	text-align: center;
}