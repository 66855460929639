.paddingTop {
	padding-top: var(--module-padding);
}

.backgroundGreen {
	background: var(--color-green);

	.titleIsPlaying {
		color: var(--color-lightest);
	}
}

.backgroundBlack {
	background: var(--color-darkest);
	color: var(--color-lightest);
}

.eyebrow span {
	margin-bottom: 2.5rem;
}

.audioPlayer {
	padding-bottom: var(--module-padding);
}

.audioContainer {
	display: flex;
	flex-direction: column;

	@media (min-width: 62.5rem) {
		flex-direction: row;
	}
}

.description {
	display: flex;
	flex-direction: column;
	position: relative;

	@media (min-width: 62.5rem) {
		padding-right: 4rem;
		width: 25%;
	}
}

.description img {
	display: block;
	margin-bottom: 3.75rem;
	max-width: 16.25rem;
	position: sticky;
	top: 6rem;
	width: 100%;
}

.podcasts {
	@media (min-width: 62.5rem) {
		width: 75%;
	}
}

.podcast {
	border-bottom: 1px solid var(--color-darkest);
}

.podcast:first-child {
	border-top: 1px solid var(--color-darkest);
}

.podcastTop {
	align-items: center;
	display: flex;
}

.title {
	margin: 2px 0 0 1.5rem;
}

.titleIsPlaying {
	composes: title;
	color: var(--color-green);
}

.collapse {
	background: transparent;
	border: 0;
	cursor: pointer;
	height: 2rem;
	margin-left: auto;
	overflow: hidden;
	padding: 0.5rem;
	position: relative;
	text-indent: -99rem;
	width: 2rem;

	&:before {
		content: "";
		background: var(--color-darkest);
		height: 1px;
		left: 0.5rem;
		position: absolute;
		top: 1rem;
		width: 1rem;
	}

	&:after {
		content: "";
		background: var(--color-darkest);
		height: 1px;
		left: 0.5rem;
		position: absolute;
		top: 1rem;
		transform: roate(0deg);
		transition: transform 100ms ease;
		width: 1rem;
	}
}

.expand {
	composes: collapse;

	&:after {
		transform: rotate(-90deg);	
	}
}

.podcastOpen {
	height: auto;
}

.podcastTop {
	padding: 2rem 0;
}

.podcastClosed {
	composes: podcastOpen;
	height: 6rem;
	overflow: hidden;
}

.podcastBottom p {
	margin: 0 3.8rem 2rem;
}

.titleButton {
	background: transparent;
	border: 0;
	cursor: pointer;
	font-family: var(--font-brand-body);
	font-size: 1rem;
}