.paddingTop {
	padding-top: var(--module-padding);
}

.backgroundGreen {
	background: var(--color-green);
}

.backgroundBlack {
	background: var(--color-darkest);
	color: var(--color-lightest);
}

.eyebrowCenter,
.eyebrowLeft,
.eyebrowRight {
	width: 100%;

	span {
		margin-bottom: 2.5rem;
	}
}

.eyebrowCenter {
	text-align: center;
}

.eyebrowLeft {
	text-align: left;
}

.eyebrowRight {
	text-align: right;
}

.carousel {
	padding-bottom: var(--module-padding);
	position: relative;
	text-align:center;
}

.carousel h3 {font-size: var(--font-size-level7); font-weight:var(--font-brand-body-weight)}
.carousel p strong {color: var(--color-light-green)}
.text {
	font-size: 0.875rem;
	margin: 0.0625rem 0 0;
	text-align: center;
}

.text p:last-child {
	margin-bottom: 0;
	padding-top:2rem;
}

.activeSlide {
	opacity: 1;
	transition: opacity 100ms ease;
}

.slide {
	composes: activeSlide;
	opacity: 0.5;
}

.carousel img {width:auto; margin:auto}
.carousel video {
	width: 90%;
}

.carouselContainer {
	position: relative;
}

.dots {
	display: flex;
	justify-content: center;
	margin-top: 4rem;
	position: relative;
	z-index: 1;
}

.backgroundBlack .dot {
	border-color: var(--color-lightest);
}

.dot {
	background: transparent;
	border: 1px solid var(--color-darkest);
	border-radius: 50%;
	cursor: pointer;
	height: 0.75rem;
	margin: 0 0.625rem;
	padding: 0;
	width: 0.75rem;
}

.dot:focus {
	outline: none;
}

.dotActive {
	composes: dot;
	border: 1.5px solid var(--color-lightest);
	box-shadow: 0 0 0 1.2px var(--color-green);
	background: var(--color-green);
}

.backgroundBlack .dotActive {
	border-color: var(--color-darkest);
}
