.paddingTop {
	padding-top: var(--module-padding);
}

.paddingBottom {
    padding-bottom: var(--module-padding);
}

.backgroundGreen {
	background: var(--color-green);
}

.backgroundBlack {
	background: var(--color-darkest);
	color: var(--color-lightest);
}

.form {
	padding-bottom: var(--module-padding);
}

.input {
	padding: 0 0 1rem 0;
	position: relative;
	color: var(--color-lightest);
}

.slimForm {
	padding-bottom: calc(var(--module-padding) / 2);
}

.slimForm.paddingTop {
	padding-top: calc(var(--module-padding) / 2);
}

.slimForm .input {
	padding: 0 0 0.5rem 0;
}

.asterisk {
	padding-bottom: 2.375rem;
}

.asterisk:before {
	content: " * ";
	color: var(--color-green);
}

.backgroundGreen .asterisk:before {
	color: var(--color-lightest);
}

.input input {
	border: 0;
	border-bottom: 1px solid #bfbfbf;
	padding: 0.5rem 0.8rem;
	font-size: 1rem;
	background: var(--color-darkest);
	border: 1px solid #828282;
	color: var(--color-lightest);
}

.inputItem0,
.firstInput {
	padding-top: 0;
}

.input textarea {
	border: 0;
	border-bottom: 1px solid #828282;
	font-size: 1rem;
	padding: 0.5rem 0.8rem;
	resize: vertical;
	color: var(--color-lightest);
}

.form form,
.slimForm form,
.selectForm {
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	max-width: 40rem;
	width: 100%;
	color: var(--color-lightest);
}

.form label,
.slimForm label,
.selectForm label {
	display: block;
	line-height: 2.3;
	margin-bottom: 1rem;
}

.form input,
.slimForm input {
	width: 100%;
}

.form textarea,
.slimForm textarea {
	width: 100%;
	background: var(--color-darkest);
	border: 1px solid #828282;
	color: var(--color-lightest);
	font-family: var(--font-brand-body);
}

.form select,
.slimForm select,
.selectForm select {
	border-radius: 4px;
	padding: 16.5px 14px;
	font-size: 1rem;
	text-transform: uppercase;
	width: 100%;
	background: var(--color-darkest);
	border: 1px solid var(--color-light-green);
	color: var(--color-lightest);
}

.inlineForm {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.inlineForm .input {
	padding-bottom: 0;
}

.inlineForm .input input {
	padding: 0.95rem;
}

.leadText {
	text-align: center;
}

.error {
	font-weight: var(--font-brand-bold-weight);
	color: var(--color-mid-green);
	text-align: center;
}

.textDark {
	color: var(--color-darkest);
}

.flex {
	align-items: center;
	display: flex;
	gap: 0.5rem;
	margin-bottom: 0.5rem;
}

.CheckboxRoot {
	background-color: var(--color-lightest);
	border: 1px solid var(--color-mid);
	width: 25px;
	height: 25px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0 2px 10px var(--color-grey);
	flex-shrink: 0;
}

.CheckboxRoot:hover {
	box-shadow: 0 1px 5px var(--color-mid-green);
}

.CheckboxRoot:focus {
	box-shadow: 0 0 0 2px black;
}
  
.CheckboxIndicator {
	color: var(--color-darkest);
}

.CheckboxIndicator svg {
	display: flex;
}

.flex .Label {
	margin-bottom: 0;
}

.Label.defaultLabel {
	background-color: transparent;
	color: var(--color-darkest);
	font-weight: var(--font-brand-bold-weight);
}

.lightForm {
	color: var(--color-darkest);
}

.lightForm .input input,
.lightForm .input textarea {
	background-color: var(--color-lightest);
	color: var(--color-darkest);
}

.RadioGroupRoot {
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
  }
  
  .RadioGroupItem {
	background-color: white;
	width: 25px;
	height: 25px;
	border-radius: 100%;
	box-shadow: 0 2px 5px var(--color-darkest);
	border: 0;
  }
  .RadioGroupItem:hover {
	background-color: var(--color-grey);
  }
  .RadioGroupItem:focus {
	box-shadow: 0 0 0 2px black;
  }
  
  .RadioGroupIndicator {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	position: relative;
  }
  .RadioGroupIndicator::after {
	content: '';
	display: block;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: var(--color-green);
  }
  

.strong {
	font-weight: var(--font-brand-bold-weight);
}

.hr {
	border-top: 1px solid var(--color-darkest);
	width: 100%;
}

.centerAligned {
	text-align: center;
}

.inputWrapper input:not([type="submit"]),
.inputWrapper textarea {
	background-color: var(--color-darkest);
	border-radius: 0.25rem;
	color: var(--color-lightest);
}

.inputWrapper textarea {
	padding: 16.5px 14px;
}

.inputWrapper label {
	border-radius: 0.25rem;
	color: var(--color-lightest);
	line-height: 1.4375em !important;
	background-color: var(--color-darkest);
	padding: 0 0.25rem;
}

.inputWrapper .defaultLabel {
	background-color: transparent;
	color: var(--color-darkest);
}

.inputWrapper label:global(.Mui-focused) {
	color: var(--color-light-green);
}

.inputWrapper fieldset,
.inputWrapper textarea {
	border-color: var(--color-light-green) !important;
}

.inputWrapper fieldset legend span {
	color: var(--color-light-green);
}

.inputWrapper :global(.MuiOutlinedInput-root) {
	background-color: var(--color-darkest);
}

.inputWrapper.lightInputs label,
.inputWrapper.lightInputs input,
.inputWrapper.lightInputs textarea {
	background-color: var(--color-lightest);
	color: var(--color-darkest);
}

.disclaimerText {
	color: var(--color-darkest);
	font-size: 0.75rem;
	line-height: 1.2;
	text-align: center;
}
