.paddingTop {
	padding-top: var(--module-padding);
}

.backgroundGreen {
	background: var(--color-green);
}

.backgroundBlack {
	background: var(--color-darkest);
	color: var(--color-lightest);
}
.backgroundBlack .eyebrow span {}

.fixedImageColumn {
	padding-bottom: var(--module-padding);
}
.fixedImageColumn h2 {
	font-size: var(--font-size-level4);
	margin-bottom:0.5rem;
}
.fixedImageColumn h3 {
	font-size: var(--font-size-level6);
	margin-bottom:0.5rem;
}
.fixedImageColumn h4 {
	margin-bottom: 2rem;
	@media(min-width: 62.5rem) {
		margin-bottom: 2.5rem;
	}
}

.imageAlignRight .fixedContainer {
	flex-direction: column-reverse;

	@media (min-width: 62.5rem) {
		flex-direction: row-reverse;
	}
}

.imageAlignLeft .fixedContainer {
	flex-direction: column-reverse;

	@media (min-width: 62.5rem) {
		flex-direction: row;
	}
}

.fixedContainer {
	display: flex;
	position: relative;
}

.textContainer,
.imageContainer {
	@media (min-width: 62.5rem) {
		width: 50%;
	}
}

.extraLargeImage .imageContainer {
	@media (min-width: 62.5rem) {
		width: 66.67%;
	}
}

.textContainer {
	@media (min-width: 62.5rem) {
		padding-left: var(--horizontal-spacing);
	}
}

.imageContainer {
	@media (min-width: 62.5rem) {
		align-items: center;
		display: flex;
	}
}

.imageContainer img,
.imageContainer video {
	display: block;
	margin: 0 auto 2.5rem;
	width: 100%;

	@media (min-width: 62.5rem) {
		margin: 0;
		position: sticky;
		top: 8rem;
		width: 100%;
	}
}

.imageAlignLeft .textContainer {
	@media (min-width: 75rem) {
		padding-right: 3.33%;
	}
}

.imageAlignRight .textContainer {
	@media (min-width: 75rem) {
		padding-left: 3.33%;
	}
}

.eyebrow span {
	margin-bottom: 2.5rem;
}

.cta {
	display: flex;
	gap: var(--horizontal-spacing);
	margin-top: 4.375rem;
}
