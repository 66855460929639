.paddingTop {
	padding-top: var(--module-padding);
}

.backgroundGreen {
	background: var(--color-green);
}

.backgroundBlack {
	background: var(--color-darkest);
	color: var(--color-lightest);
}

.posts {
	padding-bottom: var(--module-padding);
}

.eyebrow span {
	margin-bottom: 4.5rem;
}

.postEyebrow span {
	margin-bottom: 1.5rem;
}

.postContainer {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: -5rem;
}

.post {
	margin-bottom: 5rem;

	@media (min-width: 62.5rem) {
		padding-right: 8.33%;
		width: 50%;
	}

	@media (min-width: 75rem) {
		width: 33.33%;
	}
}

.featuredPost {
	composes: post;

	@media (min-width: 75rem) {
		padding-right: 0;
		width: 100%;
	}
}

.archiveHeader {
	background: var(--color-lightest);
	border-bottom: 1px solid var(--color-darkest);
	margin-bottom: 5rem;
	padding: 1.25rem 0 0.25rem;
	z-index: 1;

	@media (min-width: 62.5rem) {
		position: sticky;
		top: 107px;
	}
}

.archiveButton {
	background: transparent;
	border: 0;
	border-bottom: 1px solid var(--color-darkest);
	cursor: pointer;
	font-family: var(--font-brand-body);
	font-weight: var(--font-brand-body-weight);
	font-size: 1.25rem;
	padding: 0 0 4px;
	margin: 0 1rem 1.5rem 0;
	outline: 0;
}

.archiveButton:last-child {
	margin-right: 0;
}

.activeButton {
	composes: archiveButton;
	border-color: var(--color-green);
	color: var(--color-green);
}

.months {
	display: flex;
	justify-content: space-between;
}

.months .archiveButton {
	font-size: 0.85rem;

	@media (min-width: 75rem) {
		font-size: 1rem;
	}
}

.post .image {
	display: block;
	height: 17.5rem;
	margin-bottom: 3rem;
	width: 100%;
}

.post .image img {
	height: 100%;
	object-fit: cover;
	width: 100%;
}

.postTitle {
	font-size: 1rem;
	line-height: 1.5;
	margin-bottom: 2.5rem;
}

.noPosts {
	margin: 0 0 2rem;
}

.postTitle a {
	color: var(--color-darkest);
	text-decoration: none;
}

.postSummary {
	line-height: 2;
}

.postCta {
	margin-top: 2rem;
}

.cta {
	margin-top: 7.5rem;
	text-align: center;
}

.cta a {
	@media(min-width: 75rem) {
		width: 35%;
	}
}