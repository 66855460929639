button {
	all: unset;
  }
  
  .SelectTrigger {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	padding: 0 15px;
	font-size: 13px;
	line-height: 1;
	height: 35px;
	gap: 5px;
	background-color: white;
	color: var(--color-darkest);
	box-shadow: 0 2px 10px var(--color-mid);
  }
  .SelectTrigger:hover {
	background-color: var(--color-light);
  }
  .SelectTrigger:focus {
	box-shadow: 0 0 0 2px black;
  }
  .SelectTrigger[data-placeholder] {
	color: var(--color-darkest);
  }
  
  .SelectIcon {
	color: var(--color-mid-green);
  }
  
  .SelectContent {
	overflow: hidden;
	background-color: white;
	border-radius: 6px;
	box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  }
  
  .SelectViewport {
	padding: 5px;
  }
  
  .SelectItem {
	font-size: 0.875rem;
	line-height: 1;
	color: var(--color-darkest);
	cursor: pointer;
	border-radius: 3px;
	display: flex;
	align-items: center;
	height: 25px;
	padding: 0 35px 0 25px;
	position: relative;
	user-select: none;
  }
  .SelectItem[data-disabled] {
	color: var(--color-mid);
	pointer-events: none;
  }
  .SelectItem[data-highlighted] {
	outline: none;
	background-color: var(--color-lightest);
	color: var(--color-darkest);
  }
  
  .SelectLabel {
	padding: 0 25px;
	font-size: 12px;
	line-height: 25px;
	color: var(--color-darkest);
  }
  
  .SelectSeparator {
	height: 1px;
	background-color: var(--color-mid-green);
	margin: 5px;
  }
  
  .SelectItemIndicator {
	position: absolute;
	left: 0;
	width: 25px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
  }
  
  .SelectScrollButton {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 25px;
	background-color: white;
	color: var(--color-darkest);
	cursor: default;
  } 

.Portal {
	z-index: 999;
}