.modal {
    align-items: center;
	display: flex;
    justify-content: center;
	margin: 0 auto;
	max-width: 53.75rem;
	width: 90%;
	
	@media (max-width: 62.5rem) {
		height: 90vh;
		overflow: auto;
		top: 5vh !important;
	}
}

.modalOuter {
	background: var(--color-lightest);
	outline: 0;
}

.modalInner,
.inner {
	position: relative;
}

.modal img {
	height: 100%;
	object-fit: cover;
	width: 100%;

	@media (min-width: 62.5rem) {
		height: unset;
		object-fit: unset;
	}
}

.modalContent {
	display: flex;
	flex-direction: column;

	@media (min-width: 62.5rem) {
		flex-direction: row;
	}
}

.modal .imageBlock {
	height: 15rem;
	width: 100%;

	@media (min-width: 62.5rem) {
		align-items: center;
		background: #000;
		display: flex;
		height: auto;
		width: 36%;
	}
}

.modal .text {
	padding: 4.125rem 0;
	position: relative;

	@media (min-width: 62.5rem) {
		width: 64%;
	}
}

.closeButton {
	background: transparent;
	border: 0;
	cursor: pointer;
	display: flex;
	outline: 0;
	position: sticky;
	margin: 0 0 0 auto;
	right: 1.5rem;
	top: 1rem;
	z-index: 1;

	@media (min-width: 62.5rem) {
		position: absolute;
		top: 1.5rem;
	}
}

.modal strong {
	color: var(--color-green);
	display: block;

	@media (min-width: 32.5rem) {
		display: inline-block;
	}
}

.newsletterSignup form {
	align-items: flex-end;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	margin-top: 1rem;

	@media (min-width: 62.5rem) {
		margin-top: 2.5rem;
	}
}

.newsletterSignup .next,
.newsletterSignup input[type="submit"] {
	background-color: var(--color-light-green);
	background-image: url('./resources/arrow.svg');
	background-position: calc(100% - 1rem) 50%;
	background-repeat: no-repeat;
	background-size: 1rem;
	border: 0;
	color: var(--color-darkest);
	cursor: pointer;
	font-size: 0.85rem;
	min-width: 1.5ch;
	padding: 0.5rem 2.5rem 0.5rem 0.5rem;

	&:hover {
		background-color: var(--color-green);
	}

	&:disabled {
		cursor: not-allowed;
	}

	&:disabled:hover {
		background-color: var(--color-light-green);
	}
}
