.paddingTop {
	padding-top: var(--module-padding);
}

.backgroundGreen {
	background: var(--color-grey);
}

.backgroundBlack {
	background: var(--color-darkest);
	color: var(--color-lightest);
}

.posts {
	padding-bottom: var(--module-padding);
}

.eyebrow span {
	margin-bottom: 1rem;
}

.postEyebrow span {
	margin-bottom: 0.4rem; font-size:0.7rem; padding-left:0; opacity:0.5;
}
.postEyebrow span:before {display:none;}

.postContainer {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-bottom: -5rem;
}

.post {
	margin-bottom: 5rem;
	width:50%; padding:2%;
	@media (max-width: 900px) {
		padding: 0 1%;
		width: 100%;
	}
	@media (min-width: 75rem) {
		padding: 0 1%;
		width: 25%;
	}
}

.featuredPost {
	composes: post;

	@media (min-width: 75rem) {
		padding-right: 0;
		width: 100%;
	}
}

.post .image {
	display: block;
	height: auto;
	margin-bottom: 1rem;
	width: 100%;
}

.post .image img {
	height: 100%;
	object-fit: cover;
	width: 100%;
}

.postTitle {
	font-size: var(--font-size-level7);
	line-height: 1.5;
	margin-bottom: 1rem;
}
.backgroundBlack .postTitle a, .backgroundBlack a  {color: var(--color-lightest);}
.backgroundBlack a {border-color:var(--color-lightest);}
.postTitle a {
	color: var(--color-darkest);
font-size: var(--font-size-level7);
	text-decoration: none;
}

.postSummary {
	line-height: 2;
}

.postCta {
	margin-top: 1rem;
}

.cta {
	margin-top: 7.5rem;
	text-align: center;
}

.postCta a { padding-bottom:0; font-size: 0.9rem;}

.cta a {
	@media(min-width: 75rem) {
		width: 35%;
	}
}
