@import "../../resources/scss/csscoder.mixin";

.paddingTop {
	padding-top: var(--module-padding);
}

.backgroundGreen {
	background: var(--color-green);
}

.backgroundBlack {
	background: var(--color-darkest);
	color: var(--color-lightest);
}

.columns {
	padding-bottom: var(--module-padding);
}

.wrapper {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: -6.375rem;
}

.wrapperWithImage {
	composes: wrapper;
	margin-bottom: 0;
}

.column {
	display: flex;
	flex-direction: column;
	margin-bottom: 6.375rem;
	width: 100%;

	@media(min-width: 75rem) {
		width: 45%;
	}
}

.threeColumns .column {
	@media(min-width: 75rem) {
		width: 30%;
	}
}

.columnWithImage {
	composes: column;
	margin-bottom: 0;
}

.column .eyebrow {
	margin-top: auto;
}

.column .text {
	margin-bottom: 5rem;

	@media (min-width: 62.5rem) {
		margin-bottom: 0;
	}
}

.column .text > :last-child {
	margin-bottom: 0;
}

.threeColumns .column .text {    max-width: 300px;
    margin: 0 auto;}

		.cta {padding:1%;}

.eyebrow span {
	margin-bottom: 2.5rem;
}

.column img,
.column video {
	display: block;
	width: 100%;
}

.image {width: 190px;
    margin: 0 auto;}

.text h3,
.text h4,
.text h5,
.text h6 {
	margin-top: 0;
}

.text h4 {font-size:var(--font-size-level6); text-transform:uppercase}

.text h3 strong,
.text h4 strong,
.text h5 strong,
.text h6 strong {
	color: var(--color-green);
}

.alignLeft {
	text-align: left;
}

.alignCenter {
	text-align: center;
}

.button_wrapper {
	margin-top: 40px;
}
.button {
	display: inline-flex;
	text-decoration: none;
	color: #fff;
	margin: 0 auto;
	padding: 0 22px;
	height: 45px;
	align-items: center;
	border: 1px solid #828282;
	@include tr;

	svg {
		margin-left: 16px;
	}
}

.button:hover {
	border: 1px solid #fff;
}

.button p {
	margin: 0;
	padding: 0;
}
