.cta {
	display: inline-block;
}

.button {
	display: initial;
	width: 100%;

	@media (min-width: 62.5rem) {
		width: auto;
	}
}

.cta a {
	border-bottom: 1px solid var(--color-darkest);
	color: var(--color-darkest);
	display: inline-block;
	overflow: hidden;
	padding-bottom: 4px;
	position: relative;
	text-decoration: none;
}

.button a,
.button input,
.button button {
	background: url("./resources/arrow.svg") calc(100% - 1.375rem) 50% no-repeat;
	border-radius: 0;
	background-color: var(--color-light-green);
	border: 1px solid var(--color-light-green);
	color: var(--color-darkest);
	cursor: pointer;
	display: inline-block;
	font-size: 0.9rem;
	text-transform:uppercase;
	font-weight: var(--font-brand-bold-weight);
	outline: none;
	padding: 1rem;
	padding-right:3rem;
	text-align: left;
	transition: all 100ms ease;
	width: 100%;
	white-space: normal;

	@media (min-width: 62.5rem) {
		background: url("./resources/arrow.svg") calc(100% - 1.625rem) 50% no-repeat;
		background-color: var(--color-light-green);
		border: 1px solid var(--color-light-green);
		padding: 1rem;
		padding-right:4rem;
		width: auto;
	}
}



.button a:hover,
.button input:hover,
.button button:hover  {
	background-color: var(--color-green);
}

.colorGreen a:hover,
.colorGreen input:hover,
.colorGreen button:hover {
	background-color: var(--color-lightest);
	border-color: var(--color-lightest);
}

.colorWhite a:hover,
.colorWhite input:hover,
.colorWhite button:hover {
	background-color: var(--color-green);
	border-color: var(--color-green);
}

.colorBlack a,
.colorBlack input,
.colorBlack button {
	background: url("./resources/arrow.svg") calc(100% - 1.5rem) 50% no-repeat;
	background-color: var(--color-light-green);
	border: 1px solid var(--color-light-green);
	color: var(--color-darkest);
}

.colorBlack a:hover,
.colorBlack input:hover,
.colorBlack button:hover {
	background: url("./resources/arrow.svg") calc(100% - 1.5rem) 50% no-repeat;
	background-color: var(--color-green);
	border-color: var(--color-green);
	color: var(--color-darkest);
}

.cta svg {
	bottom: 0;
	height: 8px;
	left: 0;
	transform: rotate3d(1, 0, 0, -90deg);
	transition: all 150ms ease;
	position: absolute;
	width: 100%;
}

.cta:hover svg {
	transform: rotate3d(1, 0, 0, 0deg);
}

.cta path {
	fill: none;
	stroke: var(--color-green);
	stroke-width: 2px;
	stroke-linecap: round;
}

.aligncenter button,
.aligncenter a,
.aligncenter input {
	text-align: center;
}

.hideArrow button,
.hideArrow a,
.hideArrow input {
	background-image: none;
	padding: 1.25rem 1.375rem 1.125rem;

	@media (min-width: 62.5rem) {
		padding: 1.5rem 1.625rem 1.45rem;
	}
}

.cta input:disabled,
.cta input:disabled:hover {
	background-color: var(--color-light-green);
	cursor: not-allowed;
	opacity: 0.75;
}