.paddingTop {
	padding-top: var(--module-padding);
}

.backgroundGreen {
	background: var(--color-green);
}

.backgroundBlack {
	background: var(--color-darkest);
	color: var(--color-lightest);
}

.addBorders {
	border-bottom: 1px solid var(--color-border);
	border-top: 1px solid var(--color-border);
}

.tout {
	align-items: center;
	display: flex;
	min-height: 50vh;
	padding: var(--module-padding) 0;
	position: relative;
	text-align: left;

	@media (min-width: 62.5rem) {
		text-align: center;
	}
}

.addBottomSpacing {
	margin-bottom: var(--module-padding);
}

.eyebrow span {
	margin-bottom: 2.5rem;
}

.background {
	height: auto;
	position: absolute;
	bottom: 0;
	user-select: none;
	width: 100%;
}

.background video,
.background img {
	height: 100%;
	object-fit: contain;
	width: 100%;
}

.overlay {
	display: flex;
	flex-direction: column;
	height: 100%;
	position: relative;
	z-index: 1;
}

.overlay div:first-child {
	margin-top: auto;
}

.overlay div:last-child {
	margin-bottom: auto;
}

.tout .heading,
.tout .text {
	margin: 0 auto;
	width: 100%;

	@media (min-width: 62.5rem) {
		max-width: 46.5rem;
	}
}

.tout .text {
	margin-top: 3rem;
	display:none
}

.tout .text p {
	margin-top: 0;
}

.tout .text :last-child {
	margin-bottom: 0;
}

.tout h2,
.tout h3 {
	margin: 0;
}

.knockout .eyebrow span,
.knockout .heading h1,
.knockout .heading h2,
.knockout .heading h3,
.knockout .text p {
	background: white;
	display: inline-block;
	padding: 0.7rem;
}

.knockout .text p {
	display: inline;
}

.tout h2 {
	font-size: var(--font-size-level4-mobile);
	line-height: 1.67;

	@media (min-width: 62.5rem) {
		font-size: var(--font-size-level4);
	}
}

.tout h3 {
	font-size: var(--font-size-level5);
}

.tout h2 strong,
.tout h3 strong {
	color: var(--color-green);
}

.footer {
	margin-top: 3.5rem;
	position: relative;
	z-index: 1;
}

.removePaddingBottom {
	border-bottom: 0;
	margin-bottom: 0;
}
