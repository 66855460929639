.paddingTop {
	padding-top: var(--module-padding);
}

.backgroundGreen {
	background: var(--color-green);
}

.backgroundBlack {
	background: var(--color-darkest);
	color: var(--color-lightest);
}

.roundedImages .column {
	text-align: center;
}

.roundedImages h5 {
	line-height: 1.2;
}

.roundedImages .image {
	border-radius: 50%;
	overflow: hidden;
	border: 4px solid var(--color-green);
}

.columns {
	padding-bottom: var(--module-padding);
}

.wrapper {
	align-items: flex-start;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.wrapperWithImage {
	composes: wrapper;
	margin-bottom: 0;
}

.column {
	display: flex;
	flex-direction: column;
	margin-bottom: 1rem;
	width: 100%;
	padding:1%;

	@media(min-width: 75rem) {
		width: 33%;
	}
}

.threeColumns .column {
	@media(min-width: 75rem) {
		width: 25%;
	}
}

.columnWithImage {
	composes: column;
	margin-bottom: 0;
}

.column .eyebrow {
	margin-top: auto;
}

.column .text {
	margin-bottom: 5rem;

	@media (min-width: 62.5rem) {
		margin-bottom: 0;
	}
}

.column .text > :last-child {
	margin-bottom: 0;
}

.threeColumns .column .text { }

		.cta {padding:1%;}

.eyebrow span {
	margin-bottom: 2.5rem;
}

.column img,
.column video {
	display: block;
	width: 100%;
}

.image { margin-bottom:.5rem
}

.text h3,
.text h4,
.text h5,
.text h6 {
	margin-top: 0;
}

.text p {line-height:1; text-transform:uppercase; font-size:0.8rem; margin:0.3rem 0 0; font-weight: var(--font-brand-bold-weight); }
.text p strong {color: var(--color-green);}

.text h4 {font-size:var(--font-size-level6); text-transform:uppercase}

.text h3 strong,
.text h4 strong,
.text h5 strong,
.text h6 strong {
	color: var(--color-green);
}

.alignLeft {
	text-align: left;
}

.alignCenter {
	text-align: center;
}
