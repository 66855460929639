.footer {
	color: var(--color-lightest);
	font-weight: var(--font-weight-bold);
	background: var(--color-darkest);
	padding: 4rem 0;
	width: 100%;

	@media (min-width: 62.5rem) {
		padding: 4rem 0;
	}
}

.footerTop {
	display: flex;
	flex-direction: column;

	@media (min-width: 62.5rem) {
		flex-direction: row;
	}
}

.newsletterSignup {
	padding-right: var(--horizontal-spacing);

	@media (min-width: 62.5rem) {
		width: 33.33%;
	}
}

.newsletterSignup form {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-top: 1rem;

	@media (min-width: 62.5rem) {
		margin-top: 2.5rem;
	}
}

.newsletterSignup input[type="submit"] {
	background-color: var(--color-light-green);
	background-image: url('./resources/arrow-dark.svg');
	background-position: calc(100% - 1rem) 50%;
	background-repeat: no-repeat;
	background-size: 1rem;
	border: 0;
	color: var(--color-darkest);
	cursor: pointer;
	padding: 0.5rem 2.5rem 0.5rem 0.5rem;

	&:hover {
		background-color: var(--color-green);
	}
}

.footerLinks {
	@media (min-width: 62.5rem) {
		width: 66.67%;
	}

	@media (min-width: 75rem) {
		padding-left: 16.67%;
	}
}

.footerLinks ul {
	list-style: none;
	margin: 4rem 0 0;
	padding: 0;

	@media (min-width: 62.5rem) {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		grid-auto-flow: column;
		margin: 0;
	}
}

.footerLinks ul li {
	padding-bottom: 2.5rem;

	@media (min-width: 62.5rem) {
		padding: 0 1rem 2rem 0;
		width: 33.33%;
	}
}

.footerLinks ul li:last-child {
	padding-bottom: 0;
}

.footerLinks a {
	border-bottom: 1px solid var(--color-lightest);
	color: var(--color-lightest);
	text-decoration: none;
}

.footerBottom {
	display: flex;
	flex-direction: column;
	margin-top: 5rem;

	@media (min-width: 62.5rem) {
		flex-direction: row;
	}
}

.socialLinks {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	@media(min-width: 62.5rem) {
		width: 33.33%;
	}
}

.socialLink {
	align-items: center;
	border-radius: 50%;
	border: 1px solid var(--color-lightest);
	display: flex;
	height: 2.5rem;
	justify-content: center;
	margin: 0 0.9rem 5rem 0;
	width: 2.5rem;

	@media (min-width: 62.5rem) {
		height: 2.5rem;
		margin: 0 0.9rem 2.5rem 0;
		width: 2.5rem;
	}
}

.accreditation {
	display: flex;
	flex-wrap: wrap;
}
.accreditation img {}

.socialLink img {
	transform: scale(0.6);
}

.contactInfo {
	display: flex;
	flex-direction: column;
	font-size: 0.875rem;

	@media(min-width: 62.5rem) {
		flex-direction: row;
		width: 66.67%;
	}

	@media (min-width: 75rem) {
		padding-left: 16.67%;
	}
}

.contactInfo p {
	margin-top: 0;
}

.contactItem {
	margin-bottom: 2.75rem;
	padding-right: var(--horizontal-spacing);
	line-height: 1.5;

	@media(min-width: 75rem) {
		width: 33.33%;
	}
}

.contactInfo strong {
	font-weight: var(--font-weight-bold);
	text-transform: uppercase;
}
