.paddingTop {
	padding-top: var(--module-padding);
}

.backgroundGray {
	background: var(--color-grey);

	& .text strong {
		color: var(--color-lightest);
	}
}

.backgroundGreen {
	background: var(--color-green);

	& .text strong {
		color: var(--color-darkest);
	}
}

.backgroundBlack {
	background: var(--color-darkest);
	color: var(--color-lightest)
}

.textModule {
	padding-bottom: var(--module-padding);
}

.blogPost {
	padding-bottom: 3rem;

	.text strong {

	}
	.text a {color: var(--color-green)}
}

.textModule h2 {margin-bottom:0.5rem}

.alignmentCentered {
	@media (min-width: 62.5rem) {
		text-align: center;
	}
}

.alignmentLeft {
	text-align: left;
}

.eyebrow span {
	margin-bottom: 0.5rem;
}

.alignmentCentered h2,
.alignmentCentered h2,
.alignmentCentered h3,
.alignmentCentered h4,
.alignmentCentered h5 {
	margin-left: auto;
	margin-right: auto;

	@media (min-width: 62.5rem) {
		max-width: 40rem;
	}
}
.textModule h2 {font-size: var(--font-size-level4);}
.textModule h3 {font-size: var(--font-size-level5);}
.textModule h4 {font-size: var(--font-size-level6);}
.textModule h5 {font-size: var(--font-size-level7);}

.textModule h2,
.textModule h3,
.textModule h4,
.textModule h5 {
	margin-top: 0;


	@media (min-width: 62.5rem) {
		max-width: 40rem;
	}
}


.alignmentCentered .text p {
	margin-left: auto;
	margin-right: auto;
}

.text p {
	margin-top: 0;

	@media (min-width: 62.5rem) {
		max-width: 40rem;
	}
}

.text > *:last-child {
	margin-bottom: 0;
}

.text strong {
	color: var(--color-green);
}

.cta {
	margin-top: 4.375rem;
}

.text a {
	border-bottom: 1px solid var(--color-darkest);
	color: var(--color-darkest);
	text-decoration: none;
}
