@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,500;0,700;1,300;1,500;1,700&display=swap');

@font-face {
    font-family: "mabry-mono-regular-pro";
    src: url("./resources/mabry-mono-regular-pro.eot");
    src: url("./resources/mabry-mono-regular-pro.eot?#iefix") format("embedded-opentype"),
         url("./resources/mabry-mono-regular-pro.woff2") format("woff2"),
         url("./resources/mabry-mono-regular-pro.woff") format("woff"),
         url("./resources/mabry-mono-regular-pro.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "mabry-mono-bold-pro";
    src: url("./resources/mabry-mono-bold-pro.eot");
    src: url("./resources/mabry-mono-bold-pro.eot?#iefix") format("embedded-opentype"),
         url("./resources/mabry-mono-bold-pro.woff2") format("woff2"),
         url("./resources/mabry-mono-bold-pro.woff") format("woff"),
         url("./resources/mabry-mono-bold-pro.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "mabry-mono-italic-pro";
    src: url("./resources/mabry-mono-italic-pro.eot");
    src: url("./resources/mabry-mono-italic-pro.eot?#iefix") format("embedded-opentype"),
         url("./resources/mabry-mono-italic-pro.woff2") format("woff2"),
         url("./resources/mabry-mono-italic-pro.woff") format("woff"),
         url("./resources/mabry-mono-italic-pro.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}