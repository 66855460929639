.playIcon {
	align-items: center;
	background: transparent;
	border-radius: 1.875rem;
	border: 1px solid var(--color-lightest);
	color: var(--color-lightest);
	cursor: pointer;
	display: flex;
	font-size: 1rem;
	font-family: var(--font-brand);
	outline: 0;
	padding: 0.75rem 1.5rem;
	transition: all 100ms ease;
}

.pauseIcon {
	composes: playIcon;
	border-color: var(--color-green);
	color: var(--color-green);
}

.playIcon .icon {
	align-items: center;
	display: inline-flex;
	height: 2rem;
	margin: 0 0 0 1rem;
}

.playIcon svg {
	margin-left: 2px;
}

.playIcon span {
	line-height: 1;
}

.text {
	padding-top: 0.25rem;
}

.icon {
	align-items: center;
	border-radius: 50%;
	border: 1px solid var(--color-darkest);
	display: flex;
	height: 2rem;
	justify-content: center;
	padding: 0;
	width: 2rem;
}

.icon .text {
	padding-top: 0;
}

.icon.pauseIcon {
	border: 1px solid var(--color-green);
}

.backgroundGreen.pauseIcon {
	border: 1px solid var(--color-lightest);

	rect {
		fill: var(--color-lightest);
	}
}