.DropdownMenu {
	background: var(--color-darkest);
	border: 1px solid var(--color-mid);
	border-radius: 0.25rem;
	position: absolute;
	left: 0;
	width: 100%;
	animation-duration: 250ms;
	animation-timing-function: ease;
  }
  .DropdownMenu[data-motion='from-start'] {
	animation-name: enterFromLeft;
  }
  .DropdownMenu[data-motion='from-end'] {
	animation-name: enterFromRight;
  }
  .DropdownMenu[data-motion='to-start'] {
	animation-name: exitToLeft;
  }
  .DropdownMenu[data-motion='to-end'] {
	animation-name: exitToRight;
  }  

  .ViewportPosition {
	position: fixed;
	display: flex;
	justify-content: center;
	width: 90%;	
	top: 6rem;
	left: 0;
	perspective: 2000px;

	@media only screen and (min-width: 62.5rem) {
		position: absolute;
		top: 100%;
		width: 100%;
	}
  }

  .NavigationMenuViewport {
	position: relative;
	transform-origin: top center;
	margin-top: 10px;
	width: 100%;
	background-color: var(--color-darkest);
	border-radius: 6px;
	overflow: hidden;
	box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
	height: var(--radix-navigation-menu-viewport-height);
	transition: width, height, 300ms ease;
  }
  .NavigationMenuViewport[data-state='open'] {
	animation: scaleIn 200ms ease;
  }
  .NavigationMenuViewport[data-state='closed'] {
	animation: scaleOut 200ms ease;
  }


  @media only screen and (min-width: 62.5rem) {
	.NavigationMenuViewport {
		width: 15rem;
	  }
  }

  .NavigationMenuIndicator {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	height: 10px;
	top: 100%;
	overflow: hidden;
	z-index: 1;
	transition: width, transform 250ms ease;
  }
  .NavigationMenuIndicator[data-state='visible'] {
	animation: fadeIn 200ms ease;
  }
  .NavigationMenuIndicator[data-state='hidden'] {
	animation: fadeOut 200ms ease;
  }

  @keyframes scaleIn {
	from {
	  opacity: 0;
	  transform: rotateX(-30deg) scale(0.9);
	}
	to {
	  opacity: 1;
	  transform: rotateX(0deg) scale(1);
	}
  }
  
  @keyframes scaleOut {
	from {
	  opacity: 1;
	  transform: rotateX(0deg) scale(1);
	}
	to {
	  opacity: 0;
	  transform: rotateX(-10deg) scale(0.95);
	}
  }

  .secondaryMenu {
	list-style-type: none;
	padding: 0 1rem;
	margin: 0.5rem 0;
  }