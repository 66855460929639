.paddingTop {
	padding-top: var(--module-padding);
}

.backgroundGreen {
	background: var(--color-green);
}

.backgroundBlack {
	background: var(--color-darkest);
	color: var(--color-lightest);
}

.stats {
	padding-bottom: var(--module-padding);
}

.statsContainer {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}

.stat {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 3.75rem;
	width: 100%;


	@media (min-width: 65rem) {
		margin-bottom: 4rem;
		width: 33.33%;
	}
}


.number {
	display: flex;
	flex-wrap: wrap;
	font-size: 3rem;
	justify-content: center;
	width: 100%;
	font-weight: var(--font-brand-bold-weight);

	@media (min-width: 62.5rem) {
		font-size: 4.8rem;
	}
}

.single .stat {
	font-size: 3rem;
	width: 100%;


	@media (min-width: 62.5rem) {
		font-size: 10rem;
	}
}

.stats hr {
	border: 0;
	border-bottom: 2px solid var(--color-light-green);
	display: block;
	height: 1px;
	margin: 1rem auto 1rem;
	width: 2.3rem;

	@media (min-width: 62.5rem) {
		margin: 1rem auto 1rem;
	}
}

.backgroundBlack hr {
	border-color: var(--color-light-green);
}

.largeNumber > div:first-child {
	margin-left: auto;
	margin-right: -3ch;
	overflow: hidden;
	width: 100%;
}

.single .largeNumber > div:first-child {
	width: auto;
}

.largeNumber > div:last-child {
	margin-right: auto;
	overflow: hidden;
	width: 3ch;
}

.number {
	line-height: 1;
	text-align: center;
	transition: color 333ms ease;
}

.bigNumber {
	display: inline-flex;
	justify-content: flex-start;
	word-break: break-all;
}

.backgroundGreen .finished {
	color: var(--color-darkest);
}

.backgroundGreen .running {
	color: var(--color-lightest);
}

.backgroundWhite .finished {
	color: var(--color-darkest);
}

.backgroundWhite .running {
	color: var(--color-green);
}

.backgroundBlack .finished {
	color: var(--color-lightest);
}

.backgroundBlack .running {
	color: var(--color-green);
}

.smallNumber {
	display: none;
}

.single .smallNumber {
	color: var(--color-lightest);
	display: inline-flex;
	justify-content: flex-end;
	overflow: hidden;
	width: 3ch;
}

.copy {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
}

.text {
	margin: 0 auto;
	max-width: 27.5rem;
	width: 100%;
}

.title p {
	font-size: 1rem;
	opacity:0.7;
	line-height: 1.67;
	margin-top: 0;
	text-align: center;
}

.title p:last-child {
	margin-bottom: 0;
}

.title p strong {
	color: var(--color-green);
	font-family: var(--font-brand-body);
	font-weight: var(--font-brand-body-weight);
}

.text p {
	font-size: 1rem;
	line-height: 2;
	margin-top: 0;
	text-align: center;
}

.text p:last-child {
	margin-bottom: 0;
}

.footer {
	display: flex;
	justify-content: center;
}

.footer a {
	margin-top: 2rem;

	@media (min-width: 62.5rem) {
		margin-top: 3.5rem;
	}
}
