@import "../../resources/scss/csscoder.mixin";

.paddingTop {
  padding-top: var(--module-padding);
}

.backgroundGreen {
  background: var(--color-grey);

  & .text strong {
    color: var(--color-lightest);
  }
}

.backgroundBlack {
  background: var(--color-darkest);
  color: var(--color-lightest)
}

.textModule {
  padding-bottom: var(--module-padding);
}

.blogPost {
  padding-bottom: 3rem;

  .text strong {
    color: var(--color-darkest);
  }
}

.textModule h2 {
  margin-bottom: 0.5rem
}

.alignmentCentered {
  @media (min-width: 62.5rem) {
    text-align: center;
  }
}

.alignmentLeft {
  text-align: left;
}

.eyebrow span {
  margin-bottom: 0.5rem;
}

.alignmentCentered h2,
.alignmentCentered h2,
.alignmentCentered h3,
.alignmentCentered h4,
.alignmentCentered h5 {
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 62.5rem) {
    max-width: 40rem;
  }
}

.textModule h2 {
  font-size: var(--font-size-level4);
}

.textModule h3 {
  font-size: var(--font-size-level5);
}

.textModule h4 {
  font-size: var(--font-size-level6);
}

.textModule h5 {
  font-size: var(--font-size-level7);
}

.textModule h2,
.textModule h3,
.textModule h4,
.textModule h5 {
  margin-top: 0;


  @media (min-width: 62.5rem) {
    max-width: 40rem;
  }
}


.alignmentCentered .text p {
  margin-left: auto;
  margin-right: auto;
}

.text p {
  margin-top: 0;

  @media (min-width: 62.5rem) {
    max-width: 40rem;
  }
}

.text p:last-child {
  margin-bottom: 0;
}

.text strong {
  color: var(--color-green);
}

.cta {
  margin-top: 4.375rem;
}

.text a {
  border-bottom: 1px solid var(--color-darkest);
  color: var(--color-darkest);
  text-decoration: none;
}

.main_wrapper {
  padding: 70px 0 90px 0;
}

// columns
.wrapper_cols {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.wrapper_cols.twoUp .wrapper_cols__item {
	@include mq(md) {
		@include col(6, 2, 20);
	}	
}

.wrapper_cols__item {
  box-sizing: border-box;
  margin-bottom: 40px;
  width: 100%;
}

.wrapper_cols__item_note {
  margin-top: 20px;
}

// video box
.video_box {
  position: relative;

  &:before {
    display: block;
    content: '';
    padding-top: 56%;
  }

  & > div {
    position: absolute;
    top: 0;
    left: 0;
  }
}

// buttons
.wrap_button {
  text-align: center;
  margin-top: 70px;
}

.button {
  @include tr;
  border: 1px solid #181919;
  display: inline-flex;
  height: 50px;
  align-items: center;
  padding: 0 40px;
  text-decoration: none;
  color: #181919;

  &:hover {
    background-color: #181919;
    color: #fff;
  }
}
