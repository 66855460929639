.loading {
	align-items: center;
	display: flex;
	height: 70vh;
	justify-content: center;
	width: 100%;
}

.loading svg {
	animation: grow 700ms ease;
	height: 8px;
}

.loading path {
	fill: none;
	stroke: var(--color-green);
	stroke-width: 2px;
	stroke-linecap: round;
}

.blogBody {background: var(--color-darkest);
color: var(--color-lightest)}
.blogBody a {color: var(--color-darkest)}



.blogHeader {
	padding: 4rem 0 1rem;
	margin: 0 auto;

	@media (min-width: 62.5rem) {
		max-width: 53rem;
		padding: 8.625rem 0 1rem;
	}
}

.blogHeader h1 {
	font-size: var(--font-size-level3-mobile);
	margin: 0;

	@media (min-width: 62.5rem) {
		font-size: var(--font-size-level4);
	}
}

.postBody {
	padding-bottom: 6rem;
	max-width: 53rem;
	width: 100%;
	margin: 0 auto;
}

.postBody li {
	line-height: 2.3;
	margin-bottom: 1rem;
}

@keyframes grow {
	0% {
		width: 0rem;
	}
	100% {
		width: 18.75rem;
	}
}
